<template>
  <div class="pa-4">
    <v-container fluid>
      <h2 text color="black" class="headline">廃棄記録</h2>
      <h3 text color="black" class="mt-2">廃棄済みホルター心電計一覧</h3>
      <v-row dense>
        <v-col
          cols="12" md="12" sm="12" xl="12"
        >
          <v-toolbar
            rounded
            color="primary"
            flat
            dark
            class="pl-2 pr-2 pt-5 pb-3"
            height="auto"
          >
            <v-row dense class="align-center">

              <v-col cols="12" sm="2">
                <v-select
                  dense
                  hide-details
                  eager
                  v-model="searchSelectedField"
                  label="検索フィールド"
                  :items="searchFields"
                  @change="subscribeItems"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" v-if="searchSelectedField == SEARCH_FIELD_NAME.DEVICE_ID">
                <v-text-field
                  dense
                  hide-details
                  full-width
                  label="前方一致検索キーワード"
                  outlined
                  clearable
                  v-model="searchKeyword"
                  prepend-inner-icon="search"
                  @blur="subscribeItems"
                  @keyup.enter="subscribeItems"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3"  v-if="searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE">
                <v-select
                  dense
                  hide-details
                  eager
                  v-model="searchDateSelectedField"
                  label="日付検索項目"
                  :items="searchDateFields"
                  @change="subscribeItems"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="3" v-if="isDisposedDateRangeForm">
                <v-text-field
                  dense
                  hide-details
                  type="date"
                  v-model="searchDisposedStart"
                  clearable
                  label="検索開始日"
                  :disabled="this.searchDateSelectedField !== SEARCH_DATE_FIELD_NAME.DISPOSED"
                />
              </v-col>

              <span v-if="isDisposedDateRangeForm">&nbsp;〜&nbsp;</span>

              <v-col cols="12" sm="3" v-if="isDisposedDateRangeForm">
                <v-text-field
                  dense
                  hide-details
                  type="date"
                  v-model="searchDisposedEnd"
                  clearable
                  label="検索終了日"
                  :disabled="this.searchDateSelectedField !== SEARCH_DATE_FIELD_NAME.DISPOSED"
                />
              </v-col>

            </v-row>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row dense v-if="isOverQueryLimit">
        <v-col cols="12">
          <v-alert
            class="ma-0"
            dense
            dismissible
            outlined
            v-model="isOverQueryLimit"
            type="warning"
            prominent
          >
            <span v-for="(msg, index) in msgsOverQueryLimit" :key="index">
            {{ msg }}<br />
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            fixed-header
            height="550"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon @click="viewItemForm(item)" >
                <v-icon class="mx-3">remove_red_eye</v-icon>
              </v-btn>
              <v-btn icon @click="editItemForm(item)" >
                <v-icon class="mx-3">edit</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <device-status-chip :item="item" />
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{typeNames[item.type]}}
            </template>
            <!-- 心電計型式 -->
            <template v-slot:item.ecg_type="{ item }">
              {{ ecgType2Text(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-fab-transition>
          <v-btn
            id="rb-fab"
            color="red"
            large
            dark
            absolute
            right
            fab
            class="rb-fab"
            @click="disposeItemForm"
          >
            廃棄記録
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>

    <inventory-dialog
      v-if="mode != 'none'"
      v-model="inventoryDialogModel"
      :mode="mode"
      :item="currentItem"
      :dialog-title="dialogTitle"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import InventoryDialog from '@/components/InventoryDialog.vue';
import DeviceStatusChip from '@/components/DeviceStatusChip.vue';

let unsubscribeItems = null;
let itemsRef = null;

const SEARCH_FIELD_NAME = {
  DEVICE_ID: 'デバイスID',
  DATE_RANGE: '各種日付範囲'
};
const SEARCH_DATE_FIELD_NAME = {
  DISPOSED: '廃棄日',
  UNSPECIFIED: '指定なし'
};

export default {
  name: 'Disposals',
  components: {
    InventoryDialog,
    DeviceStatusChip,
  },
  mixins:[
  ],
  data() {
    return {
      itemsName: 'devices',
      inventoryDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '135px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '150px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '120px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '150px',
        },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchKeyword: '',
      accessTypeModel: 'all',
      // 上書きされる可能性のあるプロパティ
      searchSelectedField: SEARCH_FIELD_NAME.DATE_RANGE,
      searchFields: [
        SEARCH_FIELD_NAME.DATE_RANGE,
        SEARCH_FIELD_NAME.DEVICE_ID
      ],
      statusNames: [
        '入荷済', '発送済み', '販売スタッフが受領済み', '未使用',
        '使用中', '受付センター着荷', '解析中（アップロード済み）', '解析完了',
        '未使用期限切れ',
        '廃棄済み',
        '紛失'],
      typeNames: {
        holter: 'ホルター心電計',
        dongle: '通信用ドングル／ソフト'
      },
      searchDateSelectedField: SEARCH_DATE_FIELD_NAME.DISPOSED,
      searchDateFields: [
        SEARCH_DATE_FIELD_NAME.DISPOSED,
        SEARCH_DATE_FIELD_NAME.UNSPECIFIED
      ],
      searchDisposedStart: dateformat(
        function(){
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth() - 2, now.getDate())
        }(), 'yyyy-mm-dd'
      ),
      searchDisposedEnd: '',
      fab: true,
      dialogTitle: '',
      type: 'holter',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      isOverQueryLimit: false,
      sortBy: ['disposed'],
      sortDesc: [true],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    },
    isDisposedDateRangeForm() {
      return this.searchSelectedField == SEARCH_FIELD_NAME.DATE_RANGE;
    },
    SEARCH_FIELD_NAME() {
      return SEARCH_FIELD_NAME;
    },
    SEARCH_DATE_FIELD_NAME() {
      return SEARCH_DATE_FIELD_NAME;
    }
  },
  methods: {
    disposeItemForm() {
      console.log('disposeItemForm');
      this.mode = 'dispose';
      this.dialogTitle = '廃棄記録';
      this.inventoryDialogModel = true;
    },
    shipItemForm() {
      this.mode = 'edit';
      this.currentItem = null;
      this.dialogTitle = '出荷記録';
      this.inventoryDialogModel = true;
    },
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.mode = 'view';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
      this.inventoryDialogModel = false;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM:ss'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): '';
      } catch (e) {
        return '';
      }
    },
    subscribeItems() {
      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let query = itemsRef
        .where('deleted', '==', null)
        .where('status', '==', 9); // 廃棄済み

      // ログインユーザーで抽出データを変える
      if (this.$store.state.user.role.startsWith('center')) {
        // 販売組織スタッフの場合は禁止（ありえないが一応・・・）
        return;
      } else if (this.$store.state.user.role.startsWith('reception')) {
        // デバイス受付センタースタッフの場合
        query = query.where('reception_center_id', '==', this.$store.state.user.org_id);
      } else if (this.$store.state.user.role.startsWith('customer')) {
        // 医療機関スタッフの場合は禁止（ありえないが一応・・・）
        return;
      }

      if (this.searchSelectedField === SEARCH_FIELD_NAME.DEVICE_ID
        && this.searchKeyword.length > 0
      ) {
        // 検索キーワードで前方検索
        let startAt = this.searchKeyword;
        let endAt = this.searchKeyword + '\uffff';
        let searchFieldsValues = {
          'デバイスID':'device_id',
        };
        // 指定したキーで前方検索
        query = query
          .orderBy(searchFieldsValues[this.searchSelectedField])
          .startAt(startAt).endAt(endAt).limit(this.$store.state.configs.query_limit);
      } else {
        query = query.orderBy('disposed', 'desc')
      }

      if (this.searchSelectedField === SEARCH_FIELD_NAME.DATE_RANGE
        && this.searchDateSelectedField === SEARCH_DATE_FIELD_NAME.DISPOSED
      ) {
        if (this.searchDisposedStart !== null && this.searchDisposedStart !== '') {
          let startAt = new Date(`${this.searchDisposedStart.replaceAll('-', '/')} 00:00:00`);
          query = query.where('disposed', ">=", startAt);
        }
        if (this.searchDisposedEnd !== null && this.searchDisposedEnd !== '') {
          let endAt = new Date(`${this.searchDisposedEnd.replaceAll('-', '/')} 23:59:59`);
          query = query.where('disposed', "<=", endAt);
        }
      }

      // 上限（上限に達したことを判定するために＋１件する）
      const queryLimit = this.$store.state.configs.query_limit;
      this.msgsOverQueryLimit = [
        `表示対象データが${queryLimit}件を超えました。${queryLimit + 1}件以上は表示されません。${queryLimit}件以内にするためには各種検索条件を調整してください。`
      ];
      query = query.limit(queryLimit + 1);

      unsubscribeItems = query
        .onSnapshot((querySnapshot) => {
            // 最初および変更時の読み取り
            let listCount = 0;
            this.isOverQueryLimit = false;
            let items = [];

            // クエリ上限を超えた場合
            if (querySnapshot.docs.length > queryLimit) this.isOverQueryLimit = true;

            querySnapshot.forEach((doc) => {

              // クエリ件数が上限を超えた場合は終了
              listCount = listCount + 1;
              if (listCount > queryLimit) return;

              let data = doc.data();
              data.created = this.timestampToString(data.created);
              data.modified = this.timestampToString(data.modified);
              data.disposed = this.timestampToString(data.disposed, 'yyyy/mm/dd HH:MM');
              data.collected = this.timestampToString(data.collected, 'yyyy/mm/dd HH:MM');
              data.produced = data.produced ? dateformat(new Date(data.produced.seconds * 1000), 'yyyy/mm/dd'): '';
              data.expiration_date = data.expiration_date ? dateformat(new Date(data.expiration_date.seconds * 1000), 'yyyy/mm/dd'): '';
              data.id = doc.id;
              items.push(data);
            });
            this.items = items;
            //console.log('onSnapshot', this.items);
        }, (e) => {
          console.log('error', e);
          this.items = [];
        });
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();

  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
    searchDisposedStart(date) {
      if (date) {
        date = new Date(date + ' 00:00:00');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
    searchDisposedEnd(date) {
      if (date) {
        date = new Date(date + ' 23:59:59');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  }
}
</script>

<style>
  .theme-color {
    color: white;
    background-color:  #B3131A;
  }

  .v-speed-dial {
    position: absolute;
    top: 0.5em;
    right: 4em;

  }

  .v-btn--floating {
    position: relative;
  }

  .menu-buttons {
    position: absolute;
    top: 0.5em;
    right: 4em;
  }

  .menu-buttons button {
      margin-right: 1em;
  }

  .border-less {
    border: none !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }
</style>